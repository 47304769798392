














import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

import Form from '@/components/Forms/Form.vue';
import OfficeMap from '@/components/OfficeMap.vue';
import Awards from '@/components/Awards.vue';
import { Config } from 'client-website-ts-library/services';
import { Context, ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
    OfficeMap,
    Awards,
  },
})
export default class Contact extends Mixins(View) {
    private formCtx: Context | null = null

    mounted() {
      this.formCtx = {
        ClientWebsiteId: Config.Website.Id,
        Items: [
          {
            Type: ContextItemType.EnquiryTargetProfile,
            Id: '33676b44-274d-4fcd-b3b4-c653b5648d45',
          },
        ],
      };
    }
}
